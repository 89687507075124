<template>
  <BModal
    :heading="'Share Fundraiser'"
    :isOpen="modelValue"
    @close="toggleOpenState"
  >
    <!-- <ul class="my-2  lg:mt-6">
      <li class="mb-7 lg:hidden">
        <button
          class="focus:outline-none text-text-alternate-1 font-semibold text-sm flex items-center"
          @click="$emit('share')"
        >
          <ShareIcon
            class="h-6 stroke-2 stroke-current mr-4"
            style="stroke: #577387"
          />
          <span>Share Via</span>
        </button>
      </li>
      <li class="mb-7">
        <button
          class="focus:outline-none text-text-alternate-1 font-semibold text-sm flex items-center"
          @click="$emit('copy')"
        >
          <CopyIcon
            class="h-6 stroke-0 stroke-current fill-current mr-4"
            style="fill: #577387"
          />
          <span>Copy Link</span>
        </button>
      </li>
      <li class="mb-7">
        <button
          class="focus:outline-none text-text-alternate-1 font-semibold text-sm flex items-center"
          @click="$emit('embed')"
        >
          <EmbedIcon class="h-6 mr-4 fill-current" style="fill: #577387" />
          <span>Embed</span>
        </button>
      </li>
      <li class="mb-3">
        <button
          class="focus:outline-none text-text-alternate-1 font-semibold text-sm flex items-center"
          @click="$emit('qrcode')"
        >
          <EmbedIcon class="h-6 mr-4 fill-current" style="fill: #577387" />
          <span>QR Code</span>
        </button>
      </li>
    </ul> -->
    <div class="lg:px-12 my-4">
      <div class="grid gap-3 grid-cols-2 lg:mt-8">
        <div class="col-span-1">
          <div
            @click="$emit('share')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <ShareIcon
              class="h-6 stroke-2 stroke-current"
              style="stroke: #577387"
            />
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              Share Via
            </p>
          </div>
        </div>
        <div class="col-span-1">
          <div
            @click="$emit('copy')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <center>
              <!-- <CopyLinkIcon class="h-6 fill-current" style="fill: #577387" /> -->
              <img src="../../assets/images/copylink.png" class=" h-6" alt="" />
            </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              Copy Link
            </p>
          </div>
        </div>
      </div>
      <div class="grid gap-3 mt-3 grid-cols-2">
        <div class="col-span-1">
          <div
            @click="$emit('embed')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <EmbedIcon class="h-6 fill-current" style="fill: #577387" />
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              Embed
            </p>
          </div>
        </div>
        <div class="col-span-1">
          <div
            @click="$emit('qrcode')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <center>
              <QrIcon class="h-6 fill-current" style="fill: #577387" />
            </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              QR Code
            </p>
          </div>
        </div>
      </div>
      <div class="grid gap-3 mt-3 grid-cols-2">
        <div class="col-span-1">
          <div
            @click="$emit('flyer')"
            class="share-btn flex flex-col text-center justify-center"
          >
          <center>

            <CreateFlyerIcon class="h-6 fill-current" style="fill: #577387" />
          </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              Create Flyer
            </p>
          </div>
        </div>
        <div class="col-span-1">
          <div
            @click="$emit('makewish')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <center>
              <MakeAWishIcon class="h-6 fill-current" style="fill: #577387" />
            </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
             Make a Wish
            </p>
          </div>
        </div>
      </div>
      <div class="grid gap-3 mt-3 grid-cols-2">
        <div class="col-span-1">
          <div
            @click="$emit('tvscreen')"
            class="share-btn flex flex-col text-center justify-center"
          >
          <center>

            <TvScreenIcon class="h-6 fill-current" style="fill: #577387" />
          </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
              Large Screen
            </p>
          </div>
        </div>
        <div class="col-span-1">
          <div
            @click="$emit('promoteandearn')"
            class="share-btn flex flex-col text-center justify-center"
          >
            <center>
              <MegaPhoneIcon
                    class="h-5 stroke-current stroke-2 text-text-alternate-4"
                    style="stroke: #577387"
                  />
            </center>
            <p class="text-sm font-semibold text-text-alternate-1 mt-3">
             Promote & Earn

            </p>
          </div>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { ref } from 'vue';
import BModal from './BModal';
import { CreateFlyerIcon, MakeAWishIcon } from '../../icons';

export default {
  name: 'ShareModal',

  props: ['modelValue'],

  emits: ['copy', 'share', 'embed', 'qrcode', 'update:modelValue'],

  components: { BModal, CreateFlyerIcon, MakeAWishIcon },

  setup(props, { emit }) {
    const openState = ref(!false);

    const toggleOpenState = () => {
      emit('update:modelValue', !props.modelValue);
    };

    return { openState, toggleOpenState };
  },
};
</script>

<style lang="scss" scoped>
.share-btn {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 92px;
}
</style>
