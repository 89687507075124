<template>
  <div class="px-6 py-4">
    <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
      <button @click="handlePrev">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Updates</h2>
    </header>
    <ul
      class="my-6 text-xs xs:text-sm overflow-y-scroll"
      v-if="fundraiser.fundraiserupdates.length"
    >
      <li
        v-for="update in fundraiser.fundraiserupdates"
        :key="update.id"
        class="my-5"
      >
        <h4
          class="font-semibold text-text-alternate-3 my-2 flex justify-between"
        >
          <span>{{ dayjs(update.createdAt).format('MMM D') }}</span>
          <BButtonLoader
            class="h-4 xs:h-5 w-4 xs:w-5"
            v-if="deletingId === update.id"
          />
          <template v-else>
            <button
              class="focus:outline-none"
              @click="handleDelete(update.id)"
              v-if="!isExpired && (authUser?.id === fundraiser.userdetail.userid)"
            >
              <TrashIcon class="h-4 xs:h-5" />
            </button>
          </template>
        </h4>
        <p class="text-text-alternate-1">
          {{ update.message }}
        </p>
      </li>
    </ul>
    <div
      class="flex flex-col flex-grow justify-center items-center text-center px-6 text-text-primary"
      v-else
    >
      <CommentIcon class="my-4" />
      <h3 class="py-2 font-semibold text-lg">No Update</h3>
      <p class="px-5 py-2 text-xs">This fundraiser has no update yet.</p>
    </div>
    <form
      class="border-t border-border-primary pt-2 mb-6"
      @submit.prevent="handleSubmit"
      v-if="!isExpired && (authUser?.id === fundraiser.userdetail.userid)"
    >
      <label class="font-semibold text-sm">Add an update</label>
      <div
        class="border rounded border-border-primary flex w-full items-center overflow-hidden my-2 input-shadow"
      >
        <input
          class="p-4 focus:outline-none flex-grow text-xs"
          type="text"
          placeholder="Update here..."
          v-model="update"
          :readonly="updateResponse.isLoading"
        />
        <BButtonLoader class="mx-3 h-5 w-5" v-if="updateResponse.isLoading" />
        <button class="focus:outline-none" type="submit" v-else>
          <SendIcon class="h-6 fill-current mx-3" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { postFundraiserUpdate, deleteFundraiserUpdate } from '@/services/api';
import dayjs from 'dayjs';
import { useToast } from 'vue-toastification';

export default {
  name: 'Updates',

  props: ['fundraiser', 'authUser', 'isExpired'],

  emits: ['addUpdate', 'removeUpdate', 'prev'],

  setup(props, { emit }) {
    const update = ref('');
    const deletingId = ref('');
    const toast = useToast();
    const [updateResponse, postUpdate] = useApi(postFundraiserUpdate);

    const handleSubmit = () => {
      if (!update.value.trim()) return;
      postUpdate({
        message: update.value.trim(),
        fundraiserId: props.fundraiser.id,
      });
    };

    watch(updateResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit('addUpdate', data);
        update.value = '';
      }
    });

    const [deleteResponse, deleteUpdate] = useApi(deleteFundraiserUpdate);
    const handleDelete = (updateId) => {
      deletingId.value = updateId;
      const { id: fundraiserId } = props.fundraiser;
      deleteUpdate({ fundraiserId, updateId });
    };

    watch(deleteResponse, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Update deleted successfully');
        emit('removeUpdate', deletingId.value);
        deletingId.value = '';
      }
    });

    const handlePrev = () => emit('prev');

    return {
      update,
      dayjs,
      deletingId,
      handleSubmit,
      handlePrev,
      updateResponse,
      deleteResponse,
      handleDelete,
    };
  },
};
</script>
