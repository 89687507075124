<template>
  <div class="lg:px-6 px-2 mb-4">
    <!-- {{ wishListBlessors }} -->
    <div
      class="
        lg:h-52
        bg-bg-alternate-3
        p-4
        lg:flex
        items-center
        rounded
        make-wish-bg
      "
    >
      <div class="flex items-center">
        <div class="lg:h-36 lg:w-36 w-14 h-14 rounded-full">
          <img
            class="
              lg:h-36
              object-cover object-center
              lg:w-36
              w-14
              h-14
              rounded-full
              smallimg
            "
            :src="props.wishList?.photo"
            alt=""
          />
        </div>
        <p class="text-base lg:hidden ml-4 font-semibold text-text-alternate-1">
          <!-- {{ props.wishList }} -->
          {{ props.wishList?.firstname }} {{ props.wishList?.lastname }} -
          {{ props.wishList?.occasion }} Wish
        </p>
      </div>

      <div class="lg:ml-4">
        <p class="text-lg font-semibold hidden lg:block text-text-alternate-1">
          <!-- {{ props.wishList }} -->
          {{ props.wishList?.firstname }} {{ props.wishList?.lastname }} -
          {{ props.wishList?.occasion }} Wish
        </p>
        <p class="lg:text-sm text-xs text-text-primary mt-3">
          Hi, as you know, it’s my birthday and this year my birthday wish is to
          help raise
          <span class="font-semibold">
            N{{ props.wishList?.targetAmount.toLocaleString() }}</span
          >
          for this fundraiser below that means so much to me.
        </p>

        <p class="mt-3">
          <span class="lg:text-2xl font-bold text-text-alternate-12"
            >N{{ props.wishList?.raisedAmount.toLocaleString() }}</span
          >
          <span class="text-medium lg:text-sm text-xs text-text-alternate-3">
            raised</span
          >
        </p>

        <div class="flex items-center mt-3">
          <BProfileIcon
            v-for="(blessor, index) in wishListBlessors"
            :key="index"
            >{{ blessor.firstname[0] }} {{ blessor.lastname[0] }}</BProfileIcon
          >

          <p class="lg:text-sm text-xs text-text-primary ml-4">
            <span class="font-semibold">+{{ wishListBlessors.length }}</span>
            {{ wishListBlessors.length == 1 ? 'person' : 'people' }} turned up
            for {{ props.wishList?.firstname }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import BProfileIcon from '../../components/ui/BProfileIcon.vue';
const props = defineProps({
  wishList: {
    type: Object,
    default: null,
  },
  blessors: {
    type: Array,
    default: null,
  },
});

const wishListBlessors = ref([]);

onMounted(() => {
  wishListBlessors.value = props.blessors.filter(
    (item) => item.wishListId == props.wishList?.id
  );
});
</script>

<style>
.blessor-icon:not(:first-child) {
  margin-left: -15px;
}
@media (min-width: 1024px) {
  .make-wish-bg {
    background-image: url('../../assets/images/make-wish.png'),
      url('../../assets/images/make-a-wish.png');
    background-repeat: no-repeat, no-repeat;
    background-position: bottom right, top right;
  }
}
@media only screen and (max-width: 600px) {
  .smallimg {
    height: 56px !important;
    width: 56px !important;
  }
}
</style>
