<template>
  <BModal
    :isOpen="props.modelValue"
    :heading="'Make a Wish'"
    @close="$emit('close')"
  >
    <div
      v-if="isSuccess"
      class="min-h-50vh flex flex-col items-center text-center justify-center"
    >
      <LoadingIcon />
      <p class="text-text-alternate-1 mt-10 text-lg font-semibold">
        Make A Wish Pending
      </p>
      <p class="text-sm text-text-primary mt-4">
        An email containing instructions <br />
        has been sent to your email.
      </p>

      <button
        @click="(isSuccess = null), $emit('close')"
        class="
          h-12
          rounded
          bg-btn-bg-primary
          w-80
          mt-12
          text-text-secondary text-sm
          font-bold
        "
      >
        Done
      </button>
    </div>
    <div v-else class="lg:h-auto h-100 overflow-scroll">
      <ProjectImageCrop
        v-if="cropImage"
        type="profilepic"
        :file="photoFile"
        @save="handleSave"
        @cancel="handleCancel"
      />
      <div v-else>
        <p
          class="text-text-primary text-sm font-medium mt-3"
          :class="authUser ? 'mb-6' : null"
        >
          Fill the form below to create your Make A Wish.
        </p>
        <form @submit.prevent="handleSubmit">
          <div class="lg:grid lg:grid-cols-2 lg:gap-5">
            <template v-if="!authUser">
              <div
                class="
                  flex flex-col
                  mt-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>First Name*</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-1
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  type="text"
                  v-model.trim="firstname"
                />
              </div>
              <div
                class="
                  flex flex-col
                  mt-5
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <label>Last Name*</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-1
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  type="text"
                  v-model.trim="lastname"
                />
              </div>
              <div
                class="
                  flex flex-col
                  text-text-primary
                  mt-5
                  lg:mt-0
                  font-semibold
                  text-sm
                "
              >
                <label>Email*</label>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-1
                    mb-1
                    p-3.75
                    text-sm
                    font-medium
                    focus:outline-none
                    input-shadow
                  "
                  type="text"
                  v-model.trim="email"
                />
              </div>
            </template>
            <div
              class="
                flex flex-col
                text-text-primary
                font-semibold
                mt-5
                lg:mt-0
                text-sm
              "
            >
              <label>Phone Number</label>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-1
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                v-model.trim="phoneNumber"
              />
            </div>
            <div
              class="
                flex flex-col
                text-text-primary
                mt-5
                lg:mt-0
                font-semibold
                text-sm
              "
            >
              <label>Occasion</label>
              <BSelectInput
                class="mt-1"
                v-model="occasion"
                :options="['Birthday', 'Anniversary', 'Special Occasion']"
              />
            </div>
            <div
              class="
                flex flex-col
                text-text-primary
                mt-5
                lg:mt-0
                font-semibold
                text-sm
              "
            >
              <label>Target Amount</label>
              <!-- <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-1
                  mb-1
                  p-3.75
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                type="number"
                v-model.trim="targetAmount"
              /> -->
              <BCurrencyInput
                    name="amount"
                    @input="handleAmountInput"
                    placeholder="0.00"
                  />
            </div>
            <div
              class="
                overflow-x-auto
                p-6
                border
                w-full
                col-span-2
                rounded
                border-border-primary
                flex flex-col
                items-center
                justify-center
              "
              v-if="photo"
            >
              <div class="inline-flex gap-3">
                <div class="w-64 h-32 relative rounded-md overflow-hidden">
                  <button
                    class="
                      text-2xl
                      bg-bg-primary
                      rounded-full
                      overflow-hidden
                      h-5
                      w-5
                      absolute
                      z-10
                      right-4
                      top-4
                    "
                    @click.prevent="removePhoto()"
                  >
                    <span class="inline-block transform -translate-y-1">
                      &times;
                    </span>
                  </button>
                  <img :src="photo.base64" alt="image" />
                </div>
              </div>
              <button
                class="text-sm font-semibold mt-3"
                type="reset"
                @click="removePhoto()"
              >
                Remove photo
              </button>
            </div>
            <div
              v-else
              class="
                flex flex-col
                mb-3
                text-text-primary
                mt-5
                lg:mt-0
                font-semibold
                text-sm
              "
            >
              <div class="flex">
                <label>Photo</label>
              </div>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  flex
                  items-center
                  my-1
                  overflow-hidden
                  w-full
                "
              >
                <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                <label
                  class="
                    p-4
                    w-full
                    border-l border-border-primary
                    text-sm
                    font-semibold
                    text-text-alternate-3
                    opacity-70
                  "
                  >Choose image (Max. 1MB)
                  <input
                    type="file"
                    hidden
                    accept="image/png,image/jpeg,image/jpg"
                    @change="handleFileInput"
                    :disabled="photo"
                  />
                </label>
              </div>
              <p class="font-medium text-text-alternate-3 text-xs">
                Add your preferred cover photo
              </p>
            </div>
            <div class="col-span-2 mt-5 lg:mt-0">
              <button
                :disabled="!isFilled || isLoading"
                class="
                  h-14
                  lg:w-76
                  w-full
                  bg-btn-bg-primary
                  rounded
                  font-semibold
                  text-text-secondary
                "
              >
                <BButtonLoader class="mx-auto h-4 w-4" v-if="isLoading" />
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </BModal>
</template>

<script setup>
import BModal from './BModal.vue';
import { computed, defineProps, ref, toRefs, watch } from 'vue';
import BSelectInput from './BSelectInput.vue';
import BCurrencyInput from "@/components/ui/BCurrencyInput";
import imageCompression from 'browser-image-compression';
import ProjectImageCrop from '../../views/createProject/ProjectImageCrop.vue';
import { /*postAnImage,*/ makeWish } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
const props = defineProps({
  modelValue: {
    type: String,
  },
  fundraiserId: {
    type: String,
  },
  fundraiser: {
    type: Object,
  },
  slug: {
    type: String,
  },
});
const toggleCropImage = () => {
  cropImage.value = !cropImage.value;
};
const cropImage = ref(false);
const isCompressing = ref(false);
const photo = ref(null);
const store = useStore();
const authUser = computed(() => store.state.auth.authData);
const photoFile = ref(null);
// const { firstname = '', lastname = '', email = '' } = toRefs(authUser.value || {});
const firstname = ref(authUser.value?.firstname || '');
const lastname = ref(authUser.value?.lastname || '');
const email = ref(authUser.value?.email || '');
const phoneNumber = ref('');
const occasion = ref('');
const targetAmount = ref('');

const toast = useToast();

const handleFileInput = async ({ target }) => {
  try {
    // console.log(imageType);
    isCompressing.value = true;
    let file = target.files[0];
    photoFile.value = await imageCompression(file, {
      maxSizeMB: 0.05,
    });
    toggleCropImage();
  } catch (error) {
    console.log(error);
  } finally {
    isCompressing.value = false;
  }
};

const [makeAWishResponse, makeAWish] = useApi(makeWish);
watch(makeAWishResponse, ({ isSuccess }) => {
  if (isSuccess) {
    console.log(isSuccess);
  }
});

const { isSuccess, isLoading } = toRefs(makeAWishResponse);

const handleSave = async (image) => {
  const imageSize = Math.round(
    Buffer.from(image.substring(image.indexOf(',') + 1)).length / 1e3
  );
  if (imageSize > 1000) {
    toast.error('Image size is too large, Maximum image size should be 1MB.');
  } else {
    const { name } = photoFile.value;
    // postImage({ file: { name, mimetype: 'image/jpeg', base64: image } });
    photo.value = { base64: image, name, mimetype: 'image/jpeg' };
    photoFile.value = null;
  }
  toggleCropImage();
};

const handleCancel = () => {
  photoFile.value = null;
  toggleCropImage();
};

const removePhoto = () => {
  photo.value = null;
};

const handleSubmit = () => {
  const payload = {
    firstname: firstname.value,
    lastname: lastname.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    occasion: occasion.value,
    targetAmount: targetAmount.value,
    photo: photo.value,
    fundraiserId: props.fundraiserId,
    fundraiserTitle: props.fundraiser.title,
  };
  makeAWish(payload);
};

const handleAmountInput = (value) => {
      targetAmount.value = value
    };

// const copyLink = () => {
//   navigator.clipboard.writeText(
//     `http://blessmi.com/fundraiser/${props.slug}?wishlist_id=${makeAWishResponse.data?.id}`
//   );

//   toast.success('Link Copied!');
// };

const isFilled = computed(() => {
  if (
    (firstname.value && lastname.value,
    phoneNumber.value && occasion.value && targetAmount.value && photo.value)
  ) {
    return true;
  }
  return false;
});
</script>

<style></style>
