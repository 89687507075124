<template>
  <BModal :heading="'QR Code'" :isOpen="modelValue" @close="toggleOpenState">
    <center class="lg:px-28">
      <div
        ref="printcontent"
        class="
          my-2
          mx-auto
          border
          rounded-md
          border-border-primary
          pt-5
          max-w-l
          lg:pt-6 lg:mt-6
        "
      >
        <div
          class="rounded text-text-primary font-semibold p-4 text-xs break-all"
        >
          <qrcode-vue
            :foreground="qrcolor"
            :value="qrcode"
            :size="200"
            level="H"
          />
          <!-- <VueQr3
            :width="200"
            :height="200"
            data="https://diadal.com.ng"
            :qrOptions="{
              typeNumber: 0,
              mode: 'Byte',
              errorCorrectionLevel: 'H',
            }"
            :imageOptions="{
              hideBackgroundDots: true,
              imageSize: 0.4,
              margin: 0,
            }"
            :dotsOptions="{
              type: 'dots',
              color: '#26249a',
              gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                  { offset: 0, color: '#26249a' },
                  { offset: 1, color: '#26249a' },
                ],
              },
            }"
            :backgroundOptions="{ color: '#ffffff' }"
            image="https://diadal.com.ng/icons/favicon-96x96.png"
            :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
            :cornersDotOptions="{ type: undefined, color: '#000000' }"
            fileExt="png"
            :download="true"
            myclass="my-qur"
            imgclass="img-qr"
            downloadButton="my-button"
            :downloadOptions="{ name: 'vqr', extension: 'png' }"
          >
          </VueQr3> -->
        </div>
        <p class="text-sm text-text-alternate-1 mt-3 mb-7">
          Scan to View Blessmi Fundraiser {{ fundraiserId }}
        </p>

        <div class="bg-bg-alternate-3 flex items-center justify-center h-16">
          <p class="text-base text-text-alternate-1 font-bold">
            {{ fundraiserTitle }}
          </p>
        </div>
      </div>
      <div class="mt-8 flex justify-center">
        <div
          :class="qrcolor == '#3E3C3C' ? 'outline-white outline-black' : null"
          @click="qrcolor = '#3E3C3C'"
          class="mx-2 border rounded-full h-6 w-6 bg-text-primary"
        ></div>
        <div
          :class="qrcolor == '#2196f3' ? 'outline-white outline-black' : null"
          @click="qrcolor = '#2196f3'"
          class="mx-2 rounded-full h-6 w-6"
          style="background: #2196f3"
        ></div>
        <div
          :class="qrcolor == '#ff2929' ? 'outline-white outline-black' : null"
          @click="qrcolor = '#ff2929'"
          class="mx-2 rounded-full h-6 w-6"
          style="background: #ff2929"
        ></div>
        <div
          :class="qrcolor == '#545384' ? 'outline-white outline-black' : null"
          @click="qrcolor = '#545384'"
          class="mx-2 rounded-full h-6 w-6"
          style="background: #545384"
        ></div>
      </div>
      <button
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          rounded
          w-full
          mt-6
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click.prevent="printThis"
      >
        Save QR Code
      </button>
    </center>
  </BModal>
</template>

<script>
import { ref, computed } from 'vue';
import BModal from './BModal';
import { useToast } from 'vue-toastification';
import QrcodeVue from 'qrcode.vue';
import html2canvas from 'html2canvas';
// import { defineComponent, onBeforeUnmount, defineAsyncComponent } from 'vue';

export default {
  name: 'QrModal',

  props: [
    'modelValue',
    'slug',
    'fundraiserTitle',
    'fundraiserId',
    'wishListId',
    'promoteAndEarnId',
  ],

  emits: ['update:modelValue'],

  components: {
    BModal,
    QrcodeVue,
  },

  setup(props, { emit }) {
    const openState = ref(!false);
    const toast = useToast();
    const qrcolor = ref('#3E3C3C');
    const printcontent = ref(null);

    const qrcode = computed(() => {
      const url = `${process.env.VUE_APP_BLESS_FE_URL}/${props.slug}?${
        props.wishListId ? `wishlist_id=${props.wishListId}` : ``
      }${
        props.promoteAndEarnId ? `promotion_id=${props.promoteAndEarnId}` : ``
      }`;
      return url;
    });

    const handleCopy = async () => {
      try {
        navigator.clipboard.writeText(qrcode.value);
        toast.success('Copied to clipboard!');
        toggleOpenState();
      } catch (error) {
        console.log(error);
      }
    };

    const printThis = async () => {
      console.log('printing..');
      const el = printcontent.value;

      const options = {
        type: 'dataURL',
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement('a');
      link.setAttribute('download', `${props.fundraiserTitle}.png`);
      link.setAttribute(
        'href',
        printCanvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
      );
      link.click();

      console.log('done');
    };

    const toggleOpenState = () => {
      emit('update:modelValue', !props.modelValue);
    };

    return {
      openState,
      toggleOpenState,
      qrcode,
      handleCopy,
      qrcolor,
      printThis,
      printcontent,
    };
  },
};
</script>
