<template>
  <BModal :heading="'TV Screen'" :isOpen="modelValue" @close="toggleOpenState">
    <center>
      <p class="text-text-alternate-1 mt-1 lg:mt-10 text-lg font-semibold">
        Fundraiser Big Screen Link
      </p>
      <p class="text-sm text-text-primary mt-4">
        You can display your fundraiser on big screens like TV, Projectors, Bill
        Boards etc.
        <br class="hidden lg:block" />Copy your fundraiser TV link below.
      </p>

      <div
        class="
          border border-border-primary
          rounded
          pl-1
          h-12
          lg:w-2/4
          my-6
          text-left
          flex
          items-center
          justify-between
        "
      >
        <p class="text-xs text-text-alternate-1 my-1">
          {{ link }}
        </p>
        <button
          @click="handleCopy()"
          class="
            h-full
            px-3
            bg-btn-bg-alternate-1
            text-xs
            font-semibold
            text-text-primary
          "
        >
          Copy
        </button>
      </div>
    </center>
  </BModal>
</template>

<script>
import { ref, computed } from "vue";
import BModal from "./BModal";
import { useToast } from "vue-toastification";
import { useRoute } from 'vue-router';

export default {
  name: "QrModal",

  props: ["modelValue", "slug", "fundraiserId"],

  emits: ["update:modelValue"],

  components: {
    BModal
  },

  setup(props, { emit }) {
    const route = useRoute();
    const openState = ref(!false);
    const toast = useToast();

    const promotionId = ref(route.query.promotion_id);
    const wishListId = ref(route.query.wishlist_id);

    const link = computed(() => {
      const url = `https://tv.blessmi.com/fundraiser/${props.fundraiserId}?${
          wishListId.value ? `wishlist_id=${wishListId.value}` : ``
        }${promotionId.value ? `promotion_id=${promotionId.value}` : ``}`;
      return url;
    });

    const handleCopy = async () => {
      try {
        navigator.clipboard.writeText(link.value);
        toast.success("Copied to clipboard!");
        toggleOpenState();
      } catch (error) {
        console.log(error);
      }
    };

    const toggleOpenState = () => {
      emit("update:modelValue", !props.modelValue);
    };

    return {
      openState,
      toggleOpenState,
      handleCopy,
      link,
      promotionId,
      wishListId,
    };
  }
};
</script>
