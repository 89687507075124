<script></script>
<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7032_12158)">
      <path
        d="M38.71 20.043L28 30.7296L17.29 20.043L14 23.333L28 37.333L42 23.333L38.71 20.043Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7032_12158">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
