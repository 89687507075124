<template>
  <div class="flex flex-col min-h-screen pb-14">
    <BHeader />
    <div class="w-full lg:mb-48">
      <div class="col-span- lg:z-10 lg:px- py-0 my-0 lg:pt-24">
        <WishList
          :wishList="wishList"
          :blessors="data.blessings"
          v-if="isWishList"
        />
        <div>
          <BPageLoader v-if="isLoading" class="flex-grow" />

          <div
            class="text-center flex flex-col items-center p-6 pt-14"
            v-else-if="isFailure"
          >
            <span class="p-7 bg-bg-alternate-3 rounded-full my-5">
              <ResultEmptyIcon class="fill-current h-10" />
            </span>
            <h2 class="text-xl text-text-primary font-semibold my-4">
              Fundraiser not found
            </h2>
            <p class="text-xs xs:text-sm text-alternate-3 font-normal">
              This fundraiser may be expired, suspended, or pending approval
            </p>
            <div class="flex flex-col w-full max-w-sm mt-6">
              <router-link
                class="bg-btn-bg-primary text-btn-text-primary my-2 xs:my-3 py-4 rounded text-center text-sm xs:text-base font-bold"
                :to="{ name: 'Feeds' }"
              >
                Back to Fundraisers
              </router-link>
            </div>
          </div>

          <Report
            class="flex-grow"
            v-else-if="activeSection === 'REPORT'"
            @prev="setSection"
            :fundraiserId="data.id"
          />

          <Updates
            class="flex-grow"
            v-else-if="activeSection === 'UPDATE'"
            :fundraiser="data"
            :authUser="authUser"
            :isExpired="isOneTime && !hasNotExpired"
            @prev="setSection"
            @addUpdate="handleAddUpdate"
            @removeUpdate="handleDeleteUpdate"
          />

          <main class="flex-grow" v-else>
            <div class="relative" v-if="isOneTime">
              <Carousel :autoplay="5000" :wrapAround="true" class="lg:hidden">
                <Slide
                  v-for="{ imageurl, youTubeLink, id } in slides"
                  :key="id"
                >
                  <div class="w-full relative" style="padding-top: 66.66%">
                    <img
                      v-if="imageurl"
                      class="w-full h-full absolute top-0"
                      :src="imageurl"
                      alt="gallery"
                    />
                    <iframe
                      v-else-if="youTubeLink"
                      class="w-full h-full absolute top-0"
                      :src="`https://www.youtube.com/embed/${id}?ps=docs&controls=1`"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Slide>
                <template #addons>
                  <Pagination class="w-1/2 transform translate-x-1/2" />
                </template>
              </Carousel>

              <router-link
                v-if="authUser?.id === data.userdetail.userid"
                class="lg:hidden"
                :class="[
                  'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
                  { 'link-disabled': isOneTime && !hasNotExpired },
                ]"
                :to="{ name: 'EditFundraiser', params: { id: data.id } }"
              >
                Edit
              </router-link>
              <div
                class="lg:hidden flex items-center justify-between px-6 bg-bg-alternate-6 text-text-alternate-1 font-semibold text-xs xs:text-sm py-2"
                v-if="!isOneTime"
              >
                <router-link
                  class="ml-16 xs:ml-20 truncate"
                  :to="{
                    name: 'Profile',
                    params: { username: data.userdetail.username },
                  }"
                >
                  @{{ data.userdetail.username }}
                </router-link>
                <VerifiedIcon
                  class="h-3 pl-1 pr-5 flex-shrink-0"
                  v-if="data.userdetail.verificationStatus === 'VERIFIED'"
                />
                <VIPIcon
                  class="h-4 pl-1 pr-5 flex-shrink-0"
                  v-else-if="data.verificationStatus === 'VIP'"
                />
                <button @click="isModalShare = true" class="ml-auto">
                  <ShareIcon class="h-4 stroke-2 stroke-current" />
                </button>
                <div
                  class="rounded-full border-2 border-border-secondary w-14 xs:w-20 h-14 xs:h-20 absolute bottom-1 left-6 overflow-hidden flex items-center justify-center text-xl xs:text-2xl font-bold z-10"
                  :style="{
                    backgroundImage: `linear-gradient(144.46deg, ${randomColor({
                      luminosity: 'light',
                      seed: `${data.userdetail.firstname[0]}${data.userdetail.lastname[0]}`,
                    })} 17.06%, ${randomColor({
                      luminosity: 'dark',
                      seed: `${data.userdetail.firstname[0]}${data.userdetail.lastname[0]}`,
                    })} 103.78%)`,
                    color: '#fff',
                  }"
                >
                  <img
                    v-if="data.userdetail.profilePhoto"
                    :src="data.userdetail.profilePhoto"
                    alt="profile_image"
                  />
                  <p v-else>
                    {{ data.userdetail.firstname[0]
                    }}{{ data.userdetail.lastname[0] }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="relative lg:hidden">
                <div class="relative">
                  <img
                    style="margin-top: -8px"
                    :src="fundraiser.coverImage"
                    class="object-cover lg:h-48 h-32 w-full"
                    alt=""
                  />
                  <div
                    class="rounded-full border-2 border-border-secondary w-20 h-20 absolute top-24 left-6 overflow-hidden flex items-center justify-center text-xl xs:text-2xl font-bold z-1"
                    :style="{
                      backgroundImage: `linear-gradient(144.46deg, ${randomColor(
                        {
                          luminosity: 'light',
                          seed: `${fundraiser.userdetail.firstname[0]}${fundraiser.userdetail.lastname[0]}`,
                        }
                      )} 17.06%, ${randomColor({
                        luminosity: 'dark',
                        seed: `${fundraiser.userdetail.firstname[0]}${fundraiser.userdetail.lastname[0]}`,
                      })} 103.78%)`,
                      color: '#fff',
                    }"
                    style="margin-top: -8px"
                  >
                    <img
                      v-if="fundraiser.userdetail.profilePhoto"
                      :src="fundraiser.userdetail.profilePhoto"
                      alt="profile_image"
                      class="w-20 h-20 rounded-full"
                    />
                    <p v-else>
                      {{ fundraiser.userdetail.firstname[0]
                      }}{{ fundraiser.userdetail.lastname[0] }}
                    </p>
                  </div>
                </div>
                <router-link
                  v-if="authUser?.id === fundraiser.userdetail.userid"
                  class=""
                  :class="[
                    'absolute right-4 top-4 bg-bg-primary px-5 py-2.5 text-center rounded text-text-alternate-1 font-semibold text-sm',
                    { 'link-disabled': isOneTime && !hasNotExpired },
                  ]"
                  :to="{
                    name: 'EditFundraiser',
                    params: { id: fundraiser.id },
                  }"
                >
                  Edit
                </router-link>
                <div
                  class="flex items-center justify-between px-6 bg-bg-alternate-69 text-text-alternate-1 font-semibold text-xs xs:text-sm py-2"
                ></div>
                <div
                  class="text-center bg-bg-alternate-7 text-text-alternate-1 font-semibold text-2xs xs:text-xs py-2 mb-2"
                  :class="!isOneTime ? '' : ''"
                  v-if="fundraiser.status === 'PENDING'"
                >
                  Pending admin review
                </div>
                <!-- {{ fundraiser.organisationWebsite }} -->
                <div class="text-right flex mr-2 justify-end lg:mt-3">
                  <a
                    v-for="social in socialmedialinks"
                    :key="social.id"
                    :href="`//${social.link.replace(/^https?:\/\//, '')}`"
                    target="_blank"
                    class="mr-3"
                  >
                    <div
                      class="lg:h-10 lg:w-10 w-6 h-6 bg-bg-alternate-3 rounded-full flex justify-center items-center"
                    >
                      <FBIcon
                        class="lg:h-6 lg:w-6 w-4 h-4"
                        v-if="social.smname === 'Facebook'"
                      />
                      <IGIcon
                        class="lg:h-6 lg:w-6 w-4 h-4"
                        v-if="social.smname === 'Instagram'"
                      />
                      <TWIcon
                        class="lg:h-6 lg:w-6 w-4 h-4"
                        v-if="social.smname === 'Twitter'"
                      />
                      <YTIcon
                        class="lg:h-6 lg:w-6 w-4 h-4"
                        v-if="social.smname === 'YouTube'"
                      />
                    </div>
                  </a>
                  <a
                    :href="`//${fundraiser.organisationWebsite.replace(
                      /^https?:\/\//,
                      ''
                    )}`"
                    target="_blank"
                    class="mr-3"
                  >
                    <div
                      class="lg:h-10 lg:w-10 w-6 h-6 bg-bg-alternate-3 rounded-full flex justify-center items-center"
                    >
                      <WEBIcon class="lg:h-6 lg:w-6 w-4 h-4" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- <div
              class="
                text-center
                bg-bg-alternate-7
                text-text-alternate-1
                font-semibold
                text-2xs
                lg:hidden
                xs:text-xs
                py-2
              "
              v-if="data.status === 'PENDING'"
            >
              Pending admin review
            </div> -->

            <OneOffFundraiser
              v-if="isOneTime"
              :fundraiser="data"
              :donations="donations"
              :accounts="accounts"
              :authUser="authUser"
              :targetCoverage="targetCoverage"
              :isExpired="!hasNotExpired"
              :isOwner="isOwner"
              :searchResponse="searchResponse.data"
              :isWishList="isWishList"
              :wishListId="wishListId"
              :isPromoteAndEarn="isPromoteAndEarn"
              :promoteAndEarnId="promoteAndEarnId"
              @addUpdate="handleAddUpdate"
              @share="isModalShare = true"
              @bless="handleBless"
              @updateLikeData="updateLike"
              @update="setSection('UPDATE')"
              @setSection="setSection"
              @makeawish="isMakeAWish = true"
              @promoteFundraiser="isPromoteFundraiser = true"
            />

            <RecurringFundraiser
              v-else
              :fundraiser="data"
              :donations="donations"
              :accounts="accounts"
              :authUser="authUser"
              :isOwner="isOwner"
              :targetCoverage="targetCoverage"
              :isWishList="isWishList"
              :wishListId="wishListId"
              :isPromoteAndEarn="isPromoteAndEarn"
              :promoteAndEarnId="promoteAndEarnId"
              :searchResponse="searchResponse.data"
              @share="isModalShare = true"
              @bless="handleBless"
              @setSection="setSection"
              @makeawish="isMakeAWish = true"
              @promoteFundraiser="isPromoteFundraiser = true"
            />
            <div id="paystack-apple-pay" class="hidden"></div>
          </main>

          <ShareModal
            v-model="isModalShare"
            @share="handleModalShare"
            @embed="handleModalEmbed"
            @copy="handleModalCopy"
            @qrcode="handleModalQr"
            @flyer="handleFlyerModal"
            @makewish="handleMakeWish"
            @promoteandearn="handlePromoteAndEarn"
            @tvscreen="handleCopyTvScreenLink"
          />

          <EmbedModal
            v-model="isEmbed"
            :slug="$route.params.slug"
            :promote-and-earn-id="promoteAndEarnId"
            :wish-list-id="wishListId"
          />

          <qr-modal
            v-if="data"
            v-model="isQr"
            :fundraiserTitle="data.title"
            :slug="$route.params.slug"
            :fundraiserId="data.id"
            :promoteAndEarnId="promoteAndEarnId"
            :wishListId="wishListId"
          />

          <FlyerModal
            :targetCoverage="targetCoverage"
            :slug="$route.params.slug"
            v-if="data"
            v-model="isFlyer"
            :fundraiser="data"
            :accounts="accounts"
            :isWishList="isWishList"
            :is-promote-and-earn="isPromoteAndEarn"
            :promote-and-earn-accounts="promoteAndEarnAccounts"
            :wish-list-accounts="wishListAccounts"
            :promote-and-earn-id="promoteAndEarnId"
            :wish-list-id="wishListId"
          />

          <MakeAWishModal
            v-if="data"
            v-model="isMakeAWish"
            @close="isMakeAWish = false"
            :fundraiserId="data.id"
            :fundraiser="data"
            :slug="fundraiserSlug"
          />

          <PromoteFundraiserModal
            v-if="data"
            v-model="isPromoteFundraiser"
            @close="isPromoteFundraiser = false"
            :fundraiserId="data.id"
            :slug="fundraiserSlug"
            :fundraiser="data"
          />
          <TVScreenLinkModal
            v-if="data"
            v-model="isTVScreen"
            :fundraiserId="data.id"
            :slug="fundraiserSlug"
          />

          <BModal
            :heading="'Bless Fundraiser using card'"
            :isOpen="isBlessModalOpen"
            @close="closeBlessModal"
          >
            <BPageLoader v-if="isMakingPayment" class="flex-grow" />
            <div
              class="text-center flex flex-col items-center"
              v-else-if="blessResponse.isFailure"
            >
              <FailureIcon class="my-4 mx-auto" />
              <h2 class="text-xl text-text-primary font-semibold my-4">
                Sorry!
              </h2>
              <p class="text-xs xs:text-sm text-alternate-3">
                {{ error }}
              </p>
              <button
                class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-10 py-4 font-bold text-sm xs:text-base focus:outline-none"
                @click="closeBlessModal"
              >
                Cancel
              </button>
            </div>

            <form @submit.prevent="fundraiserBlessing" v-else>
              <div class="lg:hidden">
                <template v-if="recurBlessing">
                  <p class="text-xs font-medium mb-4">
                    You are pledging to
                    <span class="font-semibold"
                      >@{{ data.userdetail.username }}</span
                    >
                    with the amount below
                  </p>
                  <div :class="['rounded relative bg-bg-alternate-3 p-3 my-2']">
                    <span
                      :class="[
                        'text-text-alternate-1 font-bold',
                        recurBlessing.donationtype.toLowerCase(),
                      ]"
                    >
                      ${{ recurBlessing.amount.toLocaleString() }}
                    </span>
                    <span
                      class="text-text-alternate-1 font-semibold text-sm mt-1"
                    >
                      /Month
                    </span>
                  </div>
                </template>
                <div
                  v-else
                  class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
                >
                  <div
                    class="flex justify-between mb-6"
                    v-if="paymentCurrency == 'USD'"
                  >
                    <div
                      @click="handleAmountInput(amt)"
                      v-for="(amt, index) in amounts"
                      :key="index"
                      :class="
                        amount == amt
                          ? 'bg-btn-bg-primary text-btn-text-primary'
                          : null
                      "
                      class="flex items-center justify-center cursor-pointer"
                      style="
                        height: 37px;
                        width: 75px;
                        border: 1px solid #e5e5e5;
                        box-shadow: inset -2px -2px 8px rgba(0, 0, 0, 0.04);
                        border-radius: 4px;
                      "
                    >
                      ${{ amt }}
                    </div>
                  </div>
                  <label
                    >Amount ({{
                      paymentCurrency == "NGN" ? "Naira" : paymentCurrency
                    }})*</label
                  >

                  <!-- <input
                    class="
                      border
                      rounded
                      border-border-primary
                      mt-2
                      mb-1
                      p-3.75
                      text-sm
                      font-medium
                      focus:outline-none
                      input-shadow
                    "
                    type="number"
                    v-model.number="amount"
                    pattern="\d*"
                    inputmode="decimal"
                    min="0"
                  /> -->
                  <BCurrencyInput
                    name="amount"
                    @input="handleAmountInput"
                    placeholder="0.00"
                  />
                </div>
                <template v-if="!authUser">
                  <div
                    class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                  >
                    <label>Email*</label>
                    <input
                      class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                      type="email"
                      v-model.trim="email"
                    />
                  </div>
                  <div
                    class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                  >
                    <label>First Name*</label>
                    <input
                      class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                      type="text"
                      v-model.trim="firstname"
                    />
                  </div>
                  <div
                    class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                  >
                    <label>Last Name*</label>
                    <input
                      class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                      type="text"
                      v-model.trim="lastname"
                    />
                  </div>
                </template>
                <div class="mb-24 flex items-center mt-4">
                  <input type="checkbox" v-model="anonymous" />
                  <label class="font-medium text-xs ml-2">
                    Bless this fundraiser anonymously.
                  </label>
                </div>
                <div
                  class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-13"
                >
                  <button
                    class="bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full rounded text-center text-sm xs:text-base font-bold"
                    :disabled="!recurBlessing && !isFilled"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
              <div class="hidden lg:block mt-4">
                <div
                  class="flex mb-6"
                  v-if="paymentCurrency == 'USD' && !recurBlessing"
                >
                  <div
                    @click="handleAmountInput(amt)"
                    v-for="(amt, index) in amounts"
                    :key="index"
                    :class="
                      amount == amt
                        ? 'bg-btn-bg-primary text-btn-text-primary'
                        : null
                    "
                    class="flex items-center mr-3 justify-center cursor-pointer"
                    style="
                      height: 37px;
                      width: 75px;
                      border: 1px solid #e5e5e5;
                      box-shadow: inset -2px -2px 8px rgba(0, 0, 0, 0.04);
                      border-radius: 4px;
                    "
                  >
                    ${{ amt }}
                  </div>
                </div>
                <div class="grid gap-6 grid-cols-2">
                  <div class="col-span-1">
                    <template v-if="recurBlessing">
                      <p class="text-xs font-medium mb-4">
                        You are pledging to
                        <span class="font-semibold"
                          >@{{ data.userdetail.username }}</span
                        >
                        with the amount below
                      </p>
                      <div
                        :class="['rounded relative bg-bg-alternate-3 p-3 my-2']"
                      >
                        <span
                          :class="[
                            'text-text-alternate-1 font-bold',
                            recurBlessing.donationtype.toLowerCase(),
                          ]"
                        >
                          ${{ recurBlessing.amount.toLocaleString() }}
                        </span>
                        <span
                          class="text-text-alternate-1 font-semibold text-sm mt-1"
                        >
                          /Month
                        </span>
                      </div>
                    </template>
                    <div
                      v-else
                      class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
                    >
                      <label
                        >Amount ({{
                          paymentCurrency == "NGN" ? "Naira" : paymentCurrency
                        }})*</label
                      >

                      <BCurrencyInput
                        name="amount"
                        placeholder="0.00"
                        @input="handleAmountInput"
                      />
                    </div>
                    <template v-if="!authUser">
                      <div
                        class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                      >
                        <label>First Name*</label>
                        <input
                          class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                          type="text"
                          v-model.trim="firstname"
                        />
                      </div>
                    </template>
                    <div class="flex items-center mt-4">
                      <input type="checkbox" v-model="anonymous" />
                      <label class="font-medium text-xs ml-2">
                        Bless this fundraiser anonymously.
                      </label>
                    </div>
                    <button
                      class="mt-12 bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full rounded text-center text-sm xs:text-base font-bold"
                      :disabled="!recurBlessing && !isFilled"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>
                  <div class="col-span-1">
                    <template v-if="!authUser">
                      <div
                        class="flex flex-col mt-2 text-text-primary font-semibold text-sm"
                      >
                        <label>Email*</label>
                        <input
                          class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                          type="email"
                          v-model.trim="email"
                        />
                      </div>
                      <div
                        class="flex flex-col mt-5 text-text-primary font-semibold text-sm"
                      >
                        <label>Last Name*</label>
                        <input
                          class="border rounded border-border-primary mt-2 mb-1 p-3.75 text-sm font-medium focus:outline-none input-shadow"
                          type="text"
                          v-model.trim="lastname"
                        />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </form>
          </BModal>

          <BFooter class="fixed lg:hidden bottom-0 left-0 right-0 z-10" />
        </div>
      </div>
    </div>
    <BFooterLG class="hidden lg:block" />
  </div>
</template>

<script>
import { ref, computed, toRefs, watch, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useApi } from "@/cmp-functions/useApi";
import BFooter from "@/components/ui/BFooter";
import ShareModal from "@/components/ui/ShareModal";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import Updates from "@/views/fundraiser/Updates";
import Report from "@/views/fundraiser/Report";
import OneOffFundraiser from "@/views/fundraiser/OneOffFundraiser";
import RecurringFundraiser from "@/views/fundraiser/RecurringFundraiser";
import BCurrencyInput from "@/components/ui/BCurrencyInput";
// import BFundraiserCardSM from '@/components/ui/BFundraiserCardSM';
import EmbedModal from "@/components/ui/EmbedModal";
import BHeader from "@/components/ui/BHeader";
import randomColor from "randomcolor";
import { getYouTubeVideoId, formatNumber } from "@/utils";
import { useMeta } from "vue-meta";
import {
  searchFundraiser,
  getFundraiser,
  likeFundraiser,
  makePayment,
} from "@/services/api";
import { useToast } from "vue-toastification";
import BModal from "@/components/ui/BModal";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useAppState } from "@/cmp-functions/appState.js";
dayjs.extend(isSameOrAfter);
// import { v4 as uuidv4 } from 'uuid';

import PaystackPop from "@paystack/inline-js";
// import BSideMenuLG from '../components/ui/BSideMenuLG.vue';
import BFooterLG from "../components/ui/BFooterLG.vue";
import QrModal from "../components/ui/QrModal.vue";
import FlyerModal from "../components/ui/FlyerModal.vue";
import TVScreenLinkModal from "@/components/ui/TVScreenLinkModal";
import MakeAWishModal from "../components/ui/MakeAWishModal.vue";
import WishList from "../views/fundraiser/WishList.vue";
import PromoteFundraiserModal from "../components/ui/PromoteFundraiserModal.vue";
import accounting from "accounting";

export default {
  name: "FundraiserLayout",

  components: {
    BFooter,
    ShareModal,
    EmbedModal,
    Carousel,
    Slide,
    BModal,
    Pagination,
    OneOffFundraiser,
    RecurringFundraiser,
    // BFundraiserCardSM,
    Report,
    BHeader,
    Updates,
    // BSideMenuLG,
    BFooterLG,
    QrModal,
    FlyerModal,
    MakeAWishModal,
    WishList,
    PromoteFundraiserModal,
    BCurrencyInput,
    TVScreenLinkModal,
  },

  setup() {
    const activeSection = ref("");
    const isAtTop = ref(false);
    const isEmbed = ref(false);
    const isQr = ref(false);
    const isFlyer = ref(false);
    const isMakingPayment = ref(false);
    const isModalShare = ref(false);
    const isBlessModalOpen = ref(false);
    const btnContainer = ref(null);
    const paymentOption = ref(null);
    const paymentCurrency = ref("NGN");
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);
    const amounts = ref([50, 100, 200, 500]);
    const recurBlessing = ref(null);
    const { isNative } = useAppState();
    const { firstname = "", lastname = "", email = "" } = authUser.value || {};
    const fundraiser = ref({});
    const isMakeAWish = ref(false);
    const isPromoteFundraiser = ref(false);
    const isWishList = ref(false);
    const wishListId = ref("");
    const wishList = ref(null);
    const promoteAndEarn = ref(null);
    const isPromoteAndEarn = ref(false);
    const promoteAndEarnId = ref("");
    const fundraiserSlug = ref("");
    const isTVScreen = ref(false);
    const featuredImage = ref("");

    const userDetails = reactive({
      firstname,
      lastname,
      email,
      amount: "0.00",
      anonymous: false,
    });

    const isFilled = computed(() =>
      Object.values(userDetails).every(
        (value) => typeof value === "boolean" || value
      )
    );

    const isMobile = () => {
      // if (screen.width <= 768) {
      //   return true;
      // } else {
      //   return false;
      // }
      return window.matchMedia("only screen and (max-width: 768px)").matches;
    };

    const [fundraiserResponse, fetchFundraiser] = useApi(getFundraiser);
    const [searchResponse, searchForFundraiser] = useApi(searchFundraiser);
    const [likeResponse, postLikeFundraiser] = useApi(likeFundraiser);
    const [blessResponse, blessThisFundraiser] = useApi(makePayment);

    const fundraiserBlessing = () => {
      // isBlessModalOpen.value = false
      if (paymentCurrency.value == "NGN") {
        if (userDetails.amount < 50) {
          toast.error("Minimum amount to donate N50");
          return;
        }
      }
      isMakingPayment.value = true;
      const payload = {
        ...userDetails,
        fundraiserId: fundraiserResponse.data.id,
        supportedPayment: fundraiserResponse.data.supportedPayments,
        userId: authUser.value?.id || undefined,
        type: "",
        planCode: "",
        currency: paymentCurrency.value,
        isWishList: isWishList.value,
        wishListId: wishListId.value,
        isPromoteAndEarn: isPromoteAndEarn.value,
        promoteAndEarnId: promoteAndEarnId.value,
      };
      if (paymentOption.value === "PAYPAL") {
        blessWithApplePay(payload);
      } else {
        const paymentMethod = payload.supportedPayment.split(",");
        if (paymentMethod.includes("2")) {
          payload.type = 2;
        } else if (paymentMethod.includes("5")) {
          payload.type = 3;
        } else if (paymentMethod.includes("9")) {
          payload.type = 1;
        } else if (paymentOption.value === "FLUTTERWAVEAPPLENGN") {
          payload.type = 5;
        } else if (paymentOption.value === "FLUTTERWAVEAPPLEUSD") {
          payload.type = 6;
        }

        if (recurBlessing.value) {
          payload.amount = recurBlessing.value.amount;
          payload.planCode = recurBlessing.value.planCode;
        }
        blessThisFundraiser(payload, { paymentOption: paymentOption.value });
      }
    };

    const blessWithApplePay = (payload) => {
      payWithPaystack(payload, closeBlessModal);
    };

    watch(blessResponse, ({ data, isSuccess }) => {
      if (isSuccess) {
        window.location.href = data.link;
      }
    });

    watch(fundraiserResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        fundraiser.value = fundraiserResponse.data;
        const { id, title, categoryId, fundraiserType } = data;

        featuredImage.value = setFeaturedImage(data);

        searchForFundraiser({
          categoryId: fundraiserType == "PERMANENT" ? 0 : categoryId,
          offset: 0,
          limit: 4,
          userId: authUser.value?.id,
          fundraiserType,
        });
        const slug = `${id}-${title
          .toLowerCase()
          .trim()
          .replace(/[^a-z\d\s:]/g, "")
          .replace(/\s+/g, "-")}`;
        fundraiserSlug.value = slug;
        if (route.query.wishlist_id) {
          const obj = fundraiser.value.wishlists.filter(
            (item) => item.id == route.query.wishlist_id
          );
          if (obj.length && obj[0].status == "ACTIVE") {
            wishListId.value = obj[0].id;
            wishList.value = obj[0];
            isWishList.value = true;
          } else {
            router.replace({ name: "Overview", params: { slug } });
          }
        } else if (route.query.promotion_id) {
          const obj = fundraiser.value.fundraiserpromotions.filter(
            (item) => item.id == route.query.promotion_id
          );
          if (obj.length && obj[0].status == "ACTIVE") {
            promoteAndEarnId.value = obj[0].id;
            promoteAndEarn.value = obj[0];
            isPromoteAndEarn.value = true;
          } else {
            router.replace({ name: "Overview", params: { slug } });
          }
        } else {
          router.replace({ name: "Overview", params: { slug } });
        }
      }
    });

    const setFeaturedImage = (data) => {
      if (data.fundraiserType == "PERMANENT") {
        return data.coverImage;
      } else {
        const imgObj = data.images.filter((item) => item.isFeatured);
        console.log("Image Object: ", imgObj);
        if (imgObj.length) {
          return imgObj[0].imageurl;
        } else {
          return data.images[data.images.length - 1].imageurl;
        }
      }
    };

    watch(
      () => route.params.slug,
      (newValue, oldValue) => {
        const [newId] = newValue?.split("-") || [];
        const [oldId] = oldValue?.split("-") || [];
        const shouldFetch = newId && newId !== oldId;
        shouldFetch && fetchFundraiser(newId);
      },
      { immediate: true }
    );

    const donations = computed(() => {
      const donationsMapping = {};
      fundraiserResponse.data?.monthlydonations?.forEach((donation) => {
        donationsMapping[donation.amount] = donation;
      });

      return donationsMapping;
    });

    const handleBless = ({ amount, option, currency }) => {
      isBlessModalOpen.value = true;
      recurBlessing.value = donations.value[amount];
      paymentOption.value = option;
      paymentCurrency.value = currency;
    };

    const closeBlessModal = () => {
      isBlessModalOpen.value = false;
      blessResponse.isFailure = false;
      isMakingPayment.value = false;
      recurBlessing.value = null;
      paymentOption.value = null;
    };

    const targetCoverage = computed(() => {
      if (!fundraiserResponse.data) return "0%";

      const { raisedAmount, targetAmount } = fundraiserResponse.data;

      return `${Math.min(raisedAmount / targetAmount, 1) * 100}%`;
    });

    const handleAddUpdate = (update) => {
      fundraiserResponse.data.fundraiserupdates.splice(0, 0, update);
    };

    const handleDeleteUpdate = (updateId) => {
      fundraiserResponse.data.fundraiserupdates =
        fundraiserResponse.data.fundraiserupdates.filter(
          ({ id }) => updateId !== id
        );
    };

    const handleLike = () => {
      if (!authUser.value) return;
      postLikeFundraiser({
        userId: authUser.value.id,
        id: fundraiserResponse.data.id,
      });
    };

    watch(likeResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        fundraiserResponse.data.likes = data;
        if (data.isLiked) {
          toast.success("Fundraiser liked!");
        } else {
          toast.success("Fundraiser unliked!");
        }
      }
    });

    const updateLike = (data) => {
      fundraiserResponse.data.likes = data;
      if (data.isLiked) {
        toast.success("Fundraiser liked!");
      } else {
        toast.success("Fundraiser unliked!");
      }
    };

    const setSection = (section = "") => {
      activeSection.value = section;
    };

    const hasNotExpired = computed(() => {
      // const { createdAt, duration } = fundraiserResponse.data;
      // const dueDay = dayjs(createdAt).add(duration, 'day');
      // return dayjs(dueDay).isSameOrAfter(dayjs());
      const  status  = fundraiserResponse.data?.status;
      console.log('Status: ', status)
      if (
        status !== "EXPIRED" &&
        status !== "DELETED" &&
        status !== "SUSPENDED" &&
        status !== "ENDED"
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isOneTime = computed(
      () => fundraiserResponse.data?.fundraiserType !== "PERMANENT"
    );

    const closeModalShare = async () => {
      isModalShare.value = false;
    };

    const handleModalShare = async () => {
      closeModalShare();
      if (isNative) {
        window.location.href = "gonative://share/sharePage";
      } else if (navigator.canShare) {
        await navigator.share({
          title: fundraiserResponse.data.title,
          description: fundraiserResponse.data.title,
          url: document.location.href,
        });
      } else {
        alert("Sharing not supported on web");
      }
    };

    const handleModalCopy = async () => {
      closeModalShare();
      try {
        navigator.clipboard.writeText(document.location.href);
        toast.success("Copied!");
      } catch (error) {
        console.log(error);
      }
    };

    const handleModalEmbed = async () => {
      closeModalShare();
      isEmbed.value = true;
    };

    const handleModalQr = async () => {
      closeModalShare();
      isQr.value = true;
    };

    const handleFlyerModal = async () => {
      closeModalShare();
      isFlyer.value = true;
    };

    const slides = computed(() => {
      const { images, youTubeLink } = fundraiserResponse.data;
      return youTubeLink
        ? [
            ...images.slice().reverse(),
            { id: embededYoutubeId.value, youTubeLink },
          ]
        : images.slice().reverse();
    });

    const accounts = computed(() => {
      const result = [];
      fundraiserResponse.data.reservedaccounts.forEach((acc) =>
        acc.bankCode == "035" ? result.unshift(acc) : result.push(acc)
      );
      return result;
    });

    const wishListAccounts = computed(() => {
      if (isWishList.value) {
        const account = accounts.value.filter(
          (item) => item.wishListId === wishListId.value
        );
        return account;
      } else {
        return null;
      }
    });

    const promoteAndEarnAccounts = computed(() => {
      if (isPromoteAndEarn.value) {
        const account = accounts.value.filter(
          (item) => item.promoteAndEarnId === promoteAndEarnId.value
        );
        return account;
      } else {
        return null;
      }
    });
    const removeTags = (str) => {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    };
    const formattedDescription = computed(() => {
      console.log("the data: ", fundraiserResponse.data?.fundraiserDesc);
      return removeTags(fundraiserResponse.data?.fundraiserDesc ?? "");
    });

    const computedMeta = computed(() => ({
      title: `Blessmi |  ${fundraiserResponse.data?.title ?? ""}`,
      description: removeTags(fundraiserResponse.data?.fundraiserDesc ?? ""),
      // description: formattedDescription.value,
      image: featuredImage.value,
      og: {
        site_name: "Blessmi ",
        type: "Website",
        title: " " + fundraiserResponse.data?.title ?? "",
        description: formattedDescription.value,
        image: featuredImage.value,
        url: window.location.href,
      },
    }));

    const handleMakeWish = () => {
      isMakeAWish.value = true;
      isModalShare.value = false;
    };
    const handlePromoteAndEarn = () => {
      if (authUser.value) {
        isPromoteFundraiser.value = true;
        isModalShare.value = false;
      } else {
        router.push({ name: "Login" });
      }
    };

    const handleCopyTvScreenLink = () => {
      closeModalShare();
      isTVScreen.value = true;
    };
    const formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [day, month, year].join("-");
    };

    const isOwner = computed(
      () => fundraiserResponse.data.userId == authUser.value?.id
    );

    const canLike = computed(() => authUser.value && !isOwner.value);

    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(fundraiserResponse.data?.youTubeLink)
    );

    useMeta(computedMeta);
    onMounted(() => {
      console.log("Computed Meta: ", computedMeta);
      console.log("THis is it: ", formattedDescription.value);
    });

    const payWithPaystack = async (payload) => {
      const paystackPop = new PaystackPop();
      // isBlessModalOpen.value = false;
      await paystackPop
        .checkout({
          key: process.env.VUE_APP_BLESS_PAYSTACK_KEY,
          email: payload.email,
          amount: payload.amount * 100,
          firstName: payload.firstname,
          lastName: payload.lastname,
          reference: reference(),
          metadata: payload,
          currency: "USD",
          channels: ["apple_pay"],
        })
        .then(() => {
          closeBlessModal();
        })
        .catch(() => {
          closeBlessModal();
        });
    };

    const formatCurrency = () => {
      // // Remove non-numeric characters and format as currency
      // let formattedValue = event.target.value.replace(/[^\d.]/g, '');
      // formattedValue = parseFloat(formattedValue).toFixed(2);
      // // this.currencyValue = formattedValue;
      // userDetails.amount = formattedValue;

      userDetails.amount = accounting.formatMoney(userDetails.amount);
    };

    const handleAmountInput = (value) => {
      console.log(value);
      userDetails.amount = value;
    };

    const reference = () => {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    };

    const socialmedialinks = computed(() =>
      fundraiser.value.socialmedialinks.filter(({ link }) => !!link?.trim())
    );

    return {
      isAtTop,
      canLike,
      isEmbed,
      isQr,
      isFlyer,
      embededYoutubeId,
      isMakingPayment,
      isModalShare,
      isBlessModalOpen,
      isFilled,
      fundraiser,
      socialmedialinks,
      handleModalShare,
      handleModalCopy,
      handleModalEmbed,
      handleModalQr,
      handleFlyerModal,
      formatCurrency,
      accounts,
      isOneTime,
      handleLike,
      likeResponse,
      slides,
      recurBlessing,
      handleBless,
      closeBlessModal,
      ...toRefs(fundraiserResponse),
      ...toRefs(userDetails),
      searchResponse,
      hasNotExpired,
      fundraiserBlessing,
      setSection,
      activeSection,
      btnContainer,
      targetCoverage,
      handleAddUpdate,
      blessResponse,
      handleDeleteUpdate,
      handleAmountInput,
      authUser,
      randomColor,
      formatDate,
      donations,
      formatNumber,
      isMobile,
      paymentCurrency,
      amounts,
      removeTags,
      formattedDescription,
      payWithPaystack,
      reference,
      isMakeAWish,
      isWishList,
      wishListId,
      wishList,
      isPromoteAndEarn,
      promoteAndEarnId,
      promoteAndEarn,
      handleMakeWish,
      fundraiserSlug,
      isPromoteFundraiser,
      wishListAccounts,
      promoteAndEarnAccounts,
      handlePromoteAndEarn,
      handleCopyTvScreenLink,
      isTVScreen,
      updateLike,
    };
  },
};
</script>

<style lang="scss" scoped>
.link-disabled {
  pointer-events: none;
  background-color: #dcdcdc;
  color: #c4c4c4;
}

.silver {
  color: #567286;
}

.gold {
  color: #9f771f;
}

.diamond {
  color: #d15520;
}

.liked {
  color: #fd5f5f;
}
</style>

<style lang="scss">
.carousel {
  &__pagination {
    @apply absolute bottom-2.5;
  }
  &__pagination-button {
    @apply rounded-full w-1.5 xs:w-2 h-1.5 xs:h-2;
  }
}
</style>
