<template>
  <div v-if="props.modelValue" class="p-8 flex flex-col items-center">
    <div
      ref="printContent"
      id="printContent"
      class="
        border border-border-primary
        bg-bg-alternate-12
        h-150
        p-6
        flex flex-col
        items-center
        relative
      "
      style="width: 600px"
    >
      <img
        :src="imageSource"
        :class="
          fundraiser.fundraiserType !== 'PERMANENT'
            ? 'object-cover h-100'
            : null
        "
        alt=""
        crossorigin="anonymous"
      />

      <div class="h-60 absolute content bottom-24 px-5 pt-0 bg-text-primary">
        <div class="overflow-hidden overflow-ellipsis" style="height: 110px">
          <p class="text-text-secondary text-xl font-semibold">
            {{ fundraiser.title }}
          </p>
          <div
            class="h-16 text-text-secondary text-xs font-medium mt-2"
            v-html="fundraiser.fundraiserDesc"
          ></div>
        </div>

        <hr
          class="mt-3 border-t border-border-alternate-1"
          style="border-top: 1px solid #757171"
        />
        <div class="flex items-center justify-between">
          <div>
            <p class="text-lg font-semibold" style="color: #fccb90">
              &#8358;{{ fundraiser.raisedAmount.toLocaleString() }}
            </p>
            <p class="text-xs mb-4 text-text-secondary font-semibold">Raised</p>
            <div
              style="background: #242323"
              class="h-2 rounded-full mt-2 overflow-hidden"
            >
              <div
                class="bg-bg-alternate-12 rounded-full h-2"
                :style="{ width: targetCoverage }"
              ></div>
            </div>
            <p class="text-lg font-semibold text-text-secondary">
              &#8358;{{ fundraiser.targetAmount.toLocaleString() }}
            </p>
            <p class="text-xs text-text-secondary font-semibold">Target</p>
          </div>
          <div>
            <p class="text-text-secondary text-2xl font-semibold">
              Donate <br /><span class="flex items-cente"
                >Now <RightArrowWhiteIcon class="tex-xs mt-1 ml-1" />
              </span>
            </p>
          </div>
          <div class="text-right">
            <p class="text-xs text-text-secondary font-semibold">
              {{ accounts[0].bankName.toUpperCase() }}
            </p>
            <p class="text-2xl font-semibold pb-2" style="color: #fccb90">
              {{
                isWishList
                  ? wishListAccounts[0].accountNumber
                  : isPromoteAndEarn
                  ? promoteAndEarnAccounts[0].accountNumber
                  : accounts[0].accountNumber
              }}
            </p>
            <p class="text-xs text-text-secondary font-semibold">
              {{
                isWishList
                  ? wishListAccounts[0].accountName
                  : isPromoteAndEarn
                  ? promoteAndEarnAccounts[0].accountName
                  : accounts[0].accountName
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="text-center absolute bottom-8">
        <p class="text-text-secondary font-semibold text-xl flex items-center">
          Powered by
          <BrandIconWhite class="ml-1 w-32" style="margin-top: 5.5px" />
        </p>
      </div>
    </div>
  </div>
  <div v-if="props.modelValue" class="p-8 flex flex-col items-center">
    <div
      ref="printContentQr"
      id="printContentQr"
      class="
        border border-border-primary
        bg-bg-alternate-12
        h-150
        p-6
        flex flex-col
        items-center
      "
      style="width: 650px"
    >
      <div class="p-5 bg-text-primary">
        <p class="text-text-secondary text-xl font-semibold">
          {{ fundraiser.title }}
        </p>
        <div
          class="
            h-16
            overflow-hidden overflow-ellipsis
            text-text-secondary text-xs
            font-medium
            mt-2
          "
          style="height: 66px"
          v-html="fundraiser.fundraiserDesc"
        ></div>

        <hr
          class="my-5 border-t border-border-alternate-1"
          style="border-top: 1px solid #757171"
        />
        <div class="flex justify-between mb-6">
          <p class="text-lg font-bold" style="color: #fccb90">
            &#8358;{{ fundraiser.raisedAmount.toLocaleString() }}
          </p>

          <p class="text-lg font-bold text-text-secondary">
            &#8358;{{ fundraiser.targetAmount.toLocaleString() }}
          </p>
        </div>
        <div
          style="background: #242323"
          class="h-2 rounded-full my-2 overflow-hidden"
        >
          <div
            class="bg-bg-alternate-12 rounded-full h-2"
            :style="{ width: targetCoverage }"
          ></div>
        </div>
        <div class="flex justify-between">
          <p class="text-sm text-text-secondary font-semibold">Raised</p>
          <p class="text-sm text-text-secondary font-semibold">Target</p>
        </div>
        <hr
          class="my-5 border-t border-border-alternate-1"
          style="border-top: 1px solid #757171"
        />
        <p class="text-center font-semibold text-lg text-text-secondary">
          Donate now <br />
          <IconAngleDown height="36px" width="" />
        </p>
        <div class="flex justify-between mt-5">
          <div class="w-1/2">
            <p class="text-sm font-semibold text-text-secondary">
              {{ accounts[0].bankName.toUpperCase() }}
            </p>
            <p style="color: #fccb90" class="text-4xl font-semibold mb-2">
              {{
                isWishList
                  ? wishListAccounts[0].accountNumber
                  : isPromoteAndEarn
                  ? promoteAndEarnAccounts[0].accountNumber
                  : accounts[0].accountNumber
              }}
            </p>
            <p class="text-sm font-semibold text-text-secondary">
              {{
                isWishList
                  ? wishListAccounts[0].accountName
                  : isPromoteAndEarn
                  ? promoteAndEarnAccounts[0].accountName
                  : accounts[0].accountName
              }}
            </p>
          </div>
          <div style="border-right: 1px solid #757171"></div>

          <div class="flex items-center w-1/2 pl-4">
            <p class="text-sm font-semibold text-text-secondary">
              Scan to view
            </p>
            <div
              class="
                bg-bg-secondary
                ml-4
                flex
                items-center
                justify-center
                h-32
                w-32
              "
            >
              <qrcode-vue
                :foreground="'#000000'"
                :value="qrcode"
                :size="115"
                level="H"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <p class="text-text-secondary font-semibold text-xl flex items-center">
          Powered by
          <BrandIconWhite class="ml-1 w-32" style="margin-top: 5.5px" />
        </p>
      </div>
    </div>
  </div>
  <BModal
    class="h-96"
    :heading="'Create Flyer'"
    :isOpen="props.modelValue"
    @close="toggleOpenState"
  >
    <div class="">
      <div class="px-6 py-4">
        <header
          class="
            font-semibold
            lg:hidden
            text-lg
            flex
            text-text-alternate-1
            pr-6
          "
        >
          <h2 class="mx-auto">Create Flyer</h2>
        </header>
        <div>
          <p
            class="
              my-6
              xs:my-8
              font-medium
              text-text-alternate-7 text-sm
              xs:text-base
            "
          >
            Choose the flyer type you will like to download.
          </p>
          <form @submit.prevent="handleContinue">
            <label
              :class="[
                'block border rounded bg-bg-alternate-3 p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
                type === 'BASIC' ? 'border-first' : 'border-default',
              ]"
            >
              <TickIcon
                v-if="type === 'BASIC'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <h3
                class="
                  font-semibold
                  mb-2
                  text-sm
                  xs:text-base
                  text-text-alternate-1
                "
              >
                With Image
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                This flyer design comes with the large fundraiser image.
              </p>
              <input
                type="radio"
                name="Basic"
                value="BASIC"
                hidden
                v-model="type"
              />
            </label>
            <label
              :class="[
                'block border rounded bg-bg-alternate-6 p-4 xs:p-5 my-4 xs:my-5 relative cursor-pointer',
                type === 'UNUSUAL' ? 'border-second' : 'border-default',
              ]"
            >
              <TickIcon
                v-if="type === 'UNUSUAL'"
                class="h-5 xs:h-6 absolute right-4 top-4 z-10 fill-current"
                style="fill: #017622"
              />
              <h3
                class="
                  font-semibold
                  mb-2
                  text-sm
                  xs:text-base
                  text-text-alternate-1
                "
              >
                With QR Code
              </h3>
              <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
                This flyer design comes with a QR Code.
              </p>
              <input
                type="radio"
                name="Unusual"
                value="UNUSUAL"
                hidden
                v-model="type"
              />
            </label>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                font-bold
                text-sm
                xs:text-base
              "
              @click.prevent="printThis"
            >
              Download Flyer
            </button>
          </form>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script setup>
import BModal from './BModal.vue';
import IconAngleDown from '@/components/icons/IconAngleDown.vue';
import { defineProps, defineEmits, ref, computed } from 'vue';
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue';
const printContent = ref(null);
const printContentQr = ref(null);
const props = defineProps({
  modelValue: {
    type: String,
  },
  fundraiser: {
    type: Object,
  },
  qrcode: {
    type: String,
  },
  targetCoverage: {
    type: Number,
  },
  accounts: {
    type: Array,
  },
  isWishList: {
    type: Boolean,
  },
  isPromoteAndEarn: {
    type: Boolean,
  },
  wishListAccounts: {
    type: Array,
  },
  promoteAndEarnAccounts: {
    type: Array,
  },
  promoteAndEarnId: {
    type: Number,
  },
  wishListId: {
    type: Number,
  },
  slug: {
    type: String,
  },
});

const type = ref('BASIC');
const emit = defineEmits(['update']);
const toggleOpenState = () => {
  emit('update:modelValue', !props.modelValue);
};
const imageSource = computed(() => {
  const fundraiserType = props.fundraiser.fundraiserType;
  if (fundraiserType == 'ONETIME' || fundraiserType == 'ONEOFF') {
    const imgObj = props.fundraiser.images.filter((item) => item.isFeatured);
    if (imgObj.length) {
      return imgObj[0].imageurl.replace('s3.eu-central-1.amazonaws.com/', '');
    } else {
      return props.fundraiser.images[
        props.fundraiser.images.length - 1
      ].imageurl.replace('s3.eu-central-1.amazonaws.com/', '');
    }
  } else {
    return props.fundraiser.coverImage.replace(
      's3.eu-central-1.amazonaws.com/',
      ''
    );
  }
});
const qrcode = computed(() => {
  const url = `${process.env.VUE_APP_BLESS_EMBED_URL}/${props.slug}?${
    props.wishListId ? `wishlist_id=${props.wishListId}` : ``
  }${props.promoteAndEarnId ? `promotion_id=${props.promoteAndEarnId}` : ``}`;
  return url;
});
const printThis = async () => {
  console.log('printing..');
  const el = type.value == 'BASIC' ? printContent.value : printContentQr.value;

  const options = {
    type: 'dataURL',
    useCORS: true,
    allowTaint: true,
  };
  // setTimeout(async () => {
  const printCanvas = await html2canvas(el, options);
  const link = document.createElement('a');
  link.setAttribute('download', `${props.fundraiser.id}.png`);
  link.setAttribute('target', '_blank');
  link.setAttribute('href', printCanvas.toDataURL('image/png'));
  link.click();

  console.log('done');
  // }, 5000);
};
</script>

<style scoped>
.content {
  width: 500px;
  /* margin-top: -300px; */
}
</style>
