<template>
  <div class="px-6 py-4 flex flex-col">
    <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
      <button @click="$emit('prev')">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Report Fundraiser</h2>
    </header>
    <form class="my-5 text-sm flex flex-col flex-grow" @submit.prevent="handleSubmit">
      <div class="flex-grow">
        <p class="font-medium">Report this project for</p>
        <div class="flex flex-col my-6 text-text-primary font-semibold">
          <label>Category</label>
          <BModalSelect class="my-2" :options="categoryList" v-model="reason" />
        </div>
        <div class="flex flex-col my-6 text-text-primary font-semibold">
          <label>Description <span class="font-normal">(optional)</span></label>
          <textarea
            class="
              border
              rounded
              border-border-primary
              my-2
              p-4
              font-medium
              focus:outline-none
              input-shadow
            "
            type="text"
            v-model.trim="description"
          />
        </div>
      </div>
      <button
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          rounded
          w-full
          py-4
          my-4
          font-bold
          text-sm
          xs:text-base
        "
        type="submit"
        :disabled="!reason || isLoading"
      >
        <BButtonLoader class="mx-auto h-5 w-5" v-if="isLoading" />
        <span v-else>Report</span>
      </button>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import BModalSelect from '@/components/ui/BModalSelect';
import { useToast } from 'vue-toastification';
import { reportFundraiser } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';

const categoryList = ['Pornography', 'Child Abuse', 'Violence', 'Fraud', 'Religious Intolerance'];

export default {
  name: 'Report',

  props: ['fundraiserId'],

  components: { BModalSelect },

  setup(props) {
    const formInputs = reactive({
      reason: '',
      description: '',
    });

    const toast = useToast();

    const [response, postFundraiserReport] = useApi(reportFundraiser);

    const handleSubmit = () => {
      if (!formInputs.reason) return;
      const payload = {
        fundraiserId: props.fundraiserId,
        description: formInputs.description,
        reason: formInputs.reason.toUpperCase(),
      };
      postFundraiserReport(payload);
    };

    watch(response, ({ isSuccess }) => {
      if (isSuccess) {
        toast.success('Report sent!');
        formInputs.description = '';
        formInputs.reason = '';
      }
    });

    return {
      ...toRefs(formInputs),
      handleSubmit,
      categoryList,
      ...toRefs(response),
    };
  },
};
</script>
