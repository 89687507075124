<template>
  <BModal
    :isOpen="props.modelValue"
    :heading="'Promote Fundraiser'"
    @close="$emit('close')"
  >
    <div
      v-if="isSuccess"
      class="min-h-50vh flex flex-col items-center text-center justify-center"
    >
      <SuccessIcon />
      <p class="text-text-alternate-1 mt-10 text-lg font-semibold">
        Promote Fundraiser Created Successfully!
      </p>
      <p class="text-sm text-text-primary mt-4">
        Copy and Share the promotion link below to start earning from donations.
      </p>

      <div
        class="
          border border-border-primary
          rounded
          pl-1
          h-12
          lg:w-2/4
          mt-6
          text-left
          flex
          items-center
        "
      >
        <p class="text-xs text-text-alternate-1 my-1">
          {{ `http://www.blessmi.com/fundraiser/${props.slug}?promotion_id=${promoteFundraiserResponse.data?.id}` }}
        </p>
        <button
          @click="copyLink()"
          class="
            h-full
            px-3
            bg-btn-bg-alternate-1
            text-xs
            font-semibold
            text-text-primary
          "
        >
          Copy
        </button>
      </div>

      <button
        @click="(isSuccess = null), $emit('close')"
        class="
          h-12
          rounded
          bg-btn-bg-primary
          w-80
          mt-12
          text-text-secondary text-sm
          font-bold
        "
      >
        Done
      </button>
    </div>
    <div v-else class="text-center flex flex-col items-center lg:py-8 lg:px-24">
      <span
        class="
          h-24
          w-24
          rounded-full
          bg-bg-alternate-3
          flex
          items-center
          justify-center
          my-6
        "
      >
        <CashIcon class="h-8 stroke-2 stroke-current" style="stroke: #314b63" />
      </span>
      <p
        class="text-text-primary text-sm font-medium mt-6"
        :class="authUser ? 'mb-6' : null"
      >
        Promote this fundraiser, get your unique fundraiser link, share link
        with your networks that are willing to donate and start earning
        commissions.
      </p>

      <button
        @click="handleSubmit"
        class="
          h-14
          lg:w-76
          w-full
          bg-btn-bg-primary
          rounded
          font-semibold
          text-text-secondary
        "
      >
        <BButtonLoader class="mx-auto h-4 w-4" v-if="isLoading" />
        <span v-else>Continue</span>
      </button>
    </div>
  </BModal>
</template>

<script setup>
import BModal from './BModal.vue';
import { computed, defineProps, ref, toRefs, watch } from 'vue';
import { promoteAndEarn } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
const props = defineProps({
  modelValue: {
    type: String,
  },
  fundraiserId: {
    type: String,
  },
  fundraiser: {
    type: Object,
  },
  slug: {
    type: String,
  },
});

const store = useStore();
const authUser = computed(() => store.state.auth.authData);
const email = ref(authUser.value?.email);
const firstname = ref(authUser.value?.firstname);
const lastname = ref(authUser.value?.lastname);
const phoneNumber = ref(authUser.value?.phoneNumber);
const toast = useToast();
const [promoteFundraiserResponse, promoteFundraiser] = useApi(promoteAndEarn);
watch(promoteFundraiserResponse, ({ isSuccess }) => {
  if (isSuccess) {
    console.log(isSuccess);
  }
});

const { isSuccess, isLoading } = toRefs(promoteFundraiserResponse);

const handleSubmit = () => {
  console.log(authUser.value);
  const payload = {
    email: email.value,
    firstname: firstname.value,
    lastname: lastname.value,
    phoneNumber: phoneNumber.value,
    userId: authUser.value.id,
    fundraiserId: props.fundraiserId,
    fundraiserTitle: props.fundraiser.title,
  };
  promoteFundraiser(payload);
};

const copyLink = () => {
  navigator.clipboard.writeText(
    `http://www.blessmi.com/fundraiser/${props.slug}?promotion_id=${promoteFundraiserResponse.data?.id}`
  );

  toast.success('Link Copied!');
};
</script>

<style></style>
